import React from "react";
import "./SearchModal.scss";

const SearchModal = ({ isOpen, setIsOpen }) => {
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      {isOpen && (
        <>
        <div className="modal-backdrop show"/>
        <div className="modal show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content custom-modal">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title text-white ">
                  Search Live Audio Streams Instantly!
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={onClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p className="text-white">
                  To explore live audio streams by keyword, download our app for the
                  best experience.
                </p>
                <div className="d-flex flex-column flex-md-row justify-content-center gap-2 align-items-center mt-4">
                  <a href="https://apps.apple.com/app/n%C4%93dl-ai-tuner-for-live-radio/id1367332698">
                    <img
                      src="/assets/img/app-store.png"
                      alt=""
                      width={134}
                      height={44}
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.nedl.universal">
                    <img
                      src="/assets/img/play-store.png"
                      alt=""
                      width={134}
                      height={44}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default SearchModal;
