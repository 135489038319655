import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Comment from "../../components/Comment";
import SubscriptionModal from "../../components/Modal/SubscriptionModal";
import MuteUnmute from "../../components/MuteUnmute/MuteUnmute";
import { useStream } from "../../hooks/streamHooks";
import "./profile-page.scss";
import { FaArrowLeft } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import SearchModal from "../../components/Modal/SearchModal";



const ProfilePageV2 = () => {
  const { channelName } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isSerchOpen, setIsSearchOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const commentEndRef = useRef(null);
  const transcriptEndRef = useRef(null);

  const { commentStream, stream } = useStream();
  const { comments, transcripts, changeChannelName } = commentStream;

  useEffect(() => {
    changeChannelName(channelName);
  }, [channelName]);

  const isOnAir = stream?.data?.Status === "ONLINE";

  const handleGetRemainder = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    commentEndRef.current?.scrollIntoView(false);
  }, [comments]);

  useEffect(() => {
    transcriptEndRef.current?.scrollIntoView(false);
  }, [transcripts]);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${stream?.data?.AvatarUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={`background-gradient vh-100 ${
          isOpen ? "background-blur" : ""
        }`}
      >
        <div
          className="row g-0"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className={`col-12 ${isOnAir ? "col-md-7" : ""} overflow-scroll vh-100 d-flex flex-column justify-content-between align-items-start p-4`}>
            <div className="row flex-column gap-4 p-2">
              <div className="col-12 p-2 d-flex flex-column flex-wrap flex-md-row gap-4">
                <div className="d-flex gap-4">
                  <img
                    src="/assets/img/logo.png"
                    alt="logo"
                    width={96}
                    height={47}
                  />

                  <div
                    style={{ flex: 1, backgroundColor: "#D5D5D52B",alignItems: "center", maxWidth: '300px' }}
                    className="d-flex p-2 rounded-pill"
                    onClick={() => setIsSearchOpen(true)}
                  >
                    <button className="bg-transparent border-0">
                      <img
                        src="/assets/icon/search-icon.svg"
                        alt="search icon"
                      />
                    </button>
                    <p
                      className="text-white bg-transparent m-0 mx-2 border-0 text-truncate"
                      style={{maxWidth: `calc(100vw - ${isOnAir ? '330px' : '230px'})`}}
                    >
                    Search Live Audio by A Keyword
                    </p>
                    {/* <input
                      type="text"
                      id="search"
                      className="white-placeholder bg-transparent mx-2 border-0 w-100 overflow-hidden"
                      style={{
                        outline: "none",
                        color: "#FFFFFF",
                      }}
                      placeholder="Search Live Audio by A Keyword"
                    /> */}
                  </div>
                  {isOnAir && <button
                    className="bg-transparent border-0 d-md-none"
                    style={{marginLeft: "auto"}}
                    onClick={() => setIsSideBarOpen(true)}
                  >
                    <FaBars size={20} color="white" />
                  </button>}
                </div>

                <div className="d-flex w-full gap-2">
                  <a href="https://apps.apple.com/app/n%C4%93dl-ai-tuner-for-live-radio/id1367332698">
                    <img
                      src="/assets/img/app-store.png"
                      alt=""
                      width={134}
                      height={44}
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.nedl.universal">
                    <img
                      src="/assets/img/play-store.png"
                      alt=""
                      width={134}
                      height={44}
                    />
                  </a>
                </div>
              </div>
              <h5
                className={`${isOnAir ? 'col-12':'col-12 col-sm-10 col-md-8'}  fs-4 fw-normal`}
                style={{ color: "#FFFFFFBA" }}
              >
                nēdl is an AI-powered discovery app for live audio. Find Voices.
                Be Heard.&#174; Download the app to search 120,000+ live
                streams of music, news, sports, and talk—or call in to speak
                live On-Air!
              </h5>
            </div>

            <div className="d-flex flex-column gap-4 align-items-start">
              <div className="d-flex gap-2 align-items-center ">
                <div
                  className=" rounded-circle"
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: isOnAir
                      ? "#EE4044"
                      : "rgba(209, 209, 209, 1)",
                  }}
                />
                <p
                  className="text-center m-0"
                  style={{
                    color: isOnAir ? "#fff" : "rgba(209, 209, 209, 1)",
                    fontSize: "32px",
                    lineHeight: "20px",
                    fontWeight: 400,
                  }}
                >
                  {isOnAir ? "On-Air" : "Off-Air"}
                </p>
              </div>
              <h1
                className="w-full w-md-75 text-white text-break"
                style={{
                  fontSize: "96px",
                  lineHeight: "96px",
                  fontWeight: 600,
                }}
              >
                {stream?.data?.StreamName || stream?.data?.Name}{" "}
                <span
                  style={{
                    fontSize: "36px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    textWrap: "nowrap",
                  }}
                >
                  {stream?.data?.Location || "No Location"}
                </span>
              </h1>
              <button
                className="bg-transparent border-white rounded-pill text-white py-3 px-5"
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: 700,
                  outline: "none",
                }}
                onClick={handleGetRemainder}
              >
                Get A Reminder
                <img
                  src="/assets/icon/remainder.svg"
                  alt="remainder"
                  width={20}
                  height={20}
                  style={{ marginLeft: "10px" }}
                />
              </button>
            </div>
          </div>
          {isOnAir && (
            <div
              className={`col-12 col-md-5 p-4 vh-100 position-absolute ${
                isSideBarOpen ? "d-flex" : "d-none"
              } d-md-flex flex-column gap-4`}
              style={{
                backgroundColor: "rgba(65, 65, 65, 0.9)",
                paddingLeft: 10,
                paddingRight: 10,
                right: 0,
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className={`bg-transparent`}
                >
                  <FaArrowLeft
                    size={20}
                    color="white"
                    onClick={() => setIsSideBarOpen(false)}
                    className={`${
                      isSideBarOpen ? "" : "d-none"
                    } d-md-none`}
                  />
                </div>
                <MuteUnmute
                  isOnAir={isOnAir}
                  setIsOnAir={() => {}}
                  streamStatus={stream?.data.Status}
                />
                <div />
              </div>

              <div className="transcription">
                {transcripts.length === 0 ? (
                  <Fragment>
                    Thanks for your patience! When transcriptions are ready,
                    they’ll appear here.
                  </Fragment>
                ) : (
                  <div>
                    {transcripts.map((item, index) => (
                      <div key={index} className={`d-flex mb-2`}>
                        {item.avatarUrl ? (
                          <img
                            src={item.avatarUrl}
                            alt="user image"
                            className="rounded-circle "
                            style={{
                              width: "40px",
                              minWidth: "40px",
                              height: "40px",
                              marginRight: 10,
                            }}
                          />
                        ) : (
                          <div
                            className="rounded-circle"
                            style={{
                              width: "40px",
                              minWidth: "40px",
                              height: "40px",
                              marginRight: 10,
                              backgroundColor: "#8e8e93",
                            }}
                          >
                            <p
                              className="text-center"
                              style={{
                                fontSize: 20,
                                lineHeight: "40px",
                                color: "white",
                              }}
                            >
                              {item.handleName[0] ?? "U"}
                            </p>
                          </div>
                        )}

                        <div className={`flex-1`}>
                          <p style={{ fontSize: 16, marginBottom: 1 }}>
                            {item.handleName ? item.handleName : "Unknown"}
                          </p>
                          <p style={{ fontSize: 14, color: "#8e8e93" }}>
                            {item.transcript}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div ref={transcriptEndRef} />
                  </div>
                )}
              </div>
              <div
                className="mt-0 h-100"
                style={{
                  maxHeight: "100%",
                  overflowY: "scroll",
                }}
              >
                {comments.map((comment, index) => (
                  <div key={index}>
                    <Comment comment={comment} />
                  </div>
                ))}
                <div ref={commentEndRef} />
              </div>
            </div>
          )}
        </div>
      </div>

      <SubscriptionModal isOpen={isOpen} setIsOpen={setIsOpen} streamUrl={stream?.data?.StreamUrl} />
      <SearchModal isOpen={isSerchOpen} setIsOpen={setIsSearchOpen}/>
    </>
  );
};

export default ProfilePageV2;
