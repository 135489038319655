import React, { useState } from "react";
import "./subscription.scss";
import 'react-phone-input-2/lib/style.css';
import { baseUrl } from "../../services/baseQueries";
import { CustomPhoneInputComponent } from "../forms";

function SubscriptionModal({ isOpen, setIsOpen ,streamUrl}) {
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");


  const handleCancel = () => {
    setIsOpen(false);
  };

  const onConfirm = async () => {
    if (!phone || !streamUrl) {
      return;
    }
    await fetch(`${baseUrl}/agora/streams/${streamUrl}/followings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone:countryCode+phone,
      }),
    }).then(async (res) => {
      if (res.ok) {
        setIsOpen(false);
        setCountryCode("");
        setPhone("");
      }
      else{
        const msg = (await res.json()).message;
        alert(msg);
      }
    });

  };

  return (
    <>
      {isOpen && (
        <div className="subscription-modal-container d-flex justify-content-center align-items-center">
          <div className="subscription-modal p-5 d-flex justify-content-center align-items-center flex-column">
            <div className="title px-2 mb-4">
              Enter your number to get a <br /> reminder when they go On-Air
            </div>
              <CustomPhoneInputComponent phone={phone} setPhone={setPhone} countryCode={countryCode} setCountryCode={setCountryCode}/>
          
            <div className="policy mb-4 mx-4">
              By entering your number you’re agreeing to our terms of services
              &amp; Privacy policy.
            </div>
            <div className="d-flex flex-row gap-3 justify-content-between">
              <div className="cancel-btn" onClick={handleCancel}>
                Cancel
              </div>
              <div className="confirm-btn" onClick={onConfirm}>
                Confirm
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubscriptionModal;
